/* eslint-disable @typescript-eslint/no-explicit-any */
export const registrations = [
    {
        name: 'Accordion',
        import: () => import(/* webpackChunkName: "Accordion" */ '@stories/Widgets/Accordion/Accordion'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ApartmentCards',
        import: () => import(/* webpackChunkName: "ApartmentCards" */ '@stories/Widgets/ApartmentCards/ApartmentCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AreaMap',
        import: () => import(/* webpackChunkName: "AreaMap" */ '@stories/Widgets/AreaMap/AreaMap'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ArticleCardList',
        import: () => import(/* webpackChunkName: "ArticleCardList" */ '@stories/Widgets/ArticleCardList/ArticleCardList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ArticleContent',
        import: () => import(/* webpackChunkName: "ArticleContent" */ '@stories/Widgets/ArticleContent/ArticleContent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ArticleGlobalStyles',
        import: () => import(/* webpackChunkName: "ArticleGlobalStyles" */ '@stories/Widgets/ArticleGlobalStyles/ArticleGlobalStyles'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'ArticleListing',
        import: () => import(/* webpackChunkName: "ArticleListing" */ '@stories/Widgets/ArticleListing/ArticleListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AuthorSummary',
        import: () => import(/* webpackChunkName: "AuthorSummary" */ '@stories/Components/Content/AuthorSummary/AuthorSummary'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AwardsCarousel',
        import: () => import(/* webpackChunkName: "AwardsCarousel" */ '@stories/Widgets/AwardsCarousel/AwardsCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'BlogHero',
        import: () => import(/* webpackChunkName: "BlogHero" */ '@stories/Widgets/BlogHero/BlogHero'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'BlogRichText',
        import: () => import(/* webpackChunkName: "BlogRichText" */ '@stories/Widgets/BlogRichText/BlogRichText'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Breadcrumbs',
        import: () => import(/* webpackChunkName: "Breadcrumbs" */ '@stories/Components/Breadcrumbs/Breadcrumbs'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CommuteTimeCalculator',
        import: () => import(/* webpackChunkName: "CommuteTimeCalculator" */ '@stories/Widgets/CommuteTimeCalculator/CommuteTimeCalculator'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContentSignpostCards',
        import: () => import(/* webpackChunkName: "ContentSignpostCards" */ '@stories/Widgets/ContentSignpostCards/ContentSignpostCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CtaSection',
        import: () => import(/* webpackChunkName: "CtaSection" */ '@stories/Widgets/CtaSection/CtaSection'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'DevelopmentCards',
        import: () => import(/* webpackChunkName: "DevelopmentCards" */ '@stories/Widgets/DevelopmentCards/DevelopmentCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'DevelopmentHero',
        import: () => import(/* webpackChunkName: "DevelopmentHero" */ '@stories/Widgets/DevelopmentHero/DevelopmentHero'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'NeighborhoodBanner',
        import: () => import(/* webpackChunkName: "NeighborhoodBanner" */ '@stories/Widgets/NeighborhoodBanner/NeighborhoodBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'NeighborhoodImgBanner',
        import: () => import(/* webpackChunkName: "NeighborhoodImgBanner" */ '@stories/Widgets/NeighborhoodImgBanner/NeighborhoodImgBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'NeighborhoodLandmarkListing',
        import: () => import(/* webpackChunkName: "NeighborhoodLandmarkListing" */ '@stories/Widgets/NeighborhoodLandmarkListing/NeighborhoodLandmarkListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'DevelopmentInformationCards',
        import: () => import(/* webpackChunkName: "DevelopmentInformationCards" */ '@stories/Widgets/DevelopmentInformationCards/DevelopmentInformationCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'DevelopmentsMap',
        import: () => import(/* webpackChunkName: "DevelopmentsMap" */ '@stories/Widgets/DevelopmentsMap/DevelopmentsMap'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'NeighborhoodMap',
        import: () => import(/* webpackChunkName: "NeighborhoodMap" */ '@stories/Widgets/NeighborhoodMap/NeighborhoodMap'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'DownloadForm',
        import: () => import(/* webpackChunkName: "DownloadForm" */ '@stories/Widgets/DownloadForm/DownloadForm'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FloorPlans',
        import: () => import(/* webpackChunkName: "FloorPlans" */ '@stories/Widgets/FloorPlans/FloorPlans'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Footer',
        import: () => import(/* webpackChunkName: "Footer" */ '@stories/Widgets/Footer/Footer'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Form',
        import: () => import(/* webpackChunkName: "Form" */ '@stories/Widgets/Form/Form'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'GroupedIconList',
        import: () => import(/* webpackChunkName: "GroupedIconList" */ '@stories/Widgets/GroupedIconList/GroupedIconList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Header',
        import: () => import(/* webpackChunkName: "Header" */ '@stories/Widgets/Header/Header'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'HomepageHeroBanner',
        import: () => import(/* webpackChunkName: "HomepageHeroBanner" */ '@stories/Widgets/HomepageHeroBanner/HomepageHeroBanner'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'ImageGalleryCarousel',
        import: () => import(/* webpackChunkName: "ImageGalleryCarousel" */ '@stories/Widgets/ImageGalleryCarousel/ImageGalleryCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'GridGallery',
        import: () => import(/* webpackChunkName: "GridGallery" */ '@stories/Widgets/GridGallery/GridGallery'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InlineImage',
        import: () => import(/* webpackChunkName: "InlineImage" */ '@stories/Widgets/InlineImage/InlineImage'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InnerPageHero',
        import: () => import(/* webpackChunkName: "InnerPageHero" */ '@stories/Widgets/InnerPageHero/InnerPageHero'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'InPageNavigation',
        import: () => import(/* webpackChunkName: "InPageNavigation" */ '@stories/Widgets/InPageNavigation/InPageNavigation'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'InsightHero',
        import: () => import(/* webpackChunkName: "InsightHero" */ '@stories/Widgets/InsightHero/InsightHero'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'InstagramFeedEmbed',
        import: () => import(/* webpackChunkName: "InstagramFeedEmbed" */ '@stories/Widgets/InstagramFeedEmbed/InstagramFeedEmbed'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'ImageHighlighter',
        import: () => import(/* webpackChunkName: "ImageHighlighter" */ '@stories/Widgets/ImageHighlighter/ImageHighlighter'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'LocationStats',
        import: () => import(/* webpackChunkName: "LocationStats" */ '@stories/Widgets/LocationStats/LocationStats'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ProfileCarousel',
        import: () => import(/* webpackChunkName: "ProfileCarousel" */ '@stories/Widgets/ProfileCarousel/ProfileCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RichText',
        import: () => import(/* webpackChunkName: "RichText" */ '@stories/Widgets/RichText/RichText'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ShareButtons',
        import: () => import(/* webpackChunkName: "ShareButtons" */ '@stories/Components/Misc/ShareButtons/ShareButtons'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ScrollBasedGallery',
        import: () => import(/* webpackChunkName: "ScrollBasedGallery" */ '@stories/Widgets/ScrollBasedGallery/ScrollBasedGallery'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SocialPostEmbed',
        import: () => import(/* webpackChunkName: "SocialPostEmbed" */ '@stories/Widgets/SocialPostEmbed/SocialPostEmbed'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SplitContent',
        import: () => import(/* webpackChunkName: "SplitContent" */ '@stories/Widgets/SplitContent/SplitContent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'StickyArticleList',
        import: () => import(/* webpackChunkName: "StickyArticleList" */ '@stories/Components/Content/StickyArticleList/StickyArticleList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TestimonialCarousel',
        import: () => import(/* webpackChunkName: "TestimonialCarousel" */ '@stories/Widgets/TestimonialCarousel/TestimonialCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Media',
        import: () => import(/* webpackChunkName: "Media" */ '@stories/Widgets/Media/Media'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'VirtualTour',
        import: () => import(/* webpackChunkName: "VirtualTour" */ '@stories/Widgets/VirtualTour/VirtualTour'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ModalForm',
        import: () => import(/* webpackChunkName: "ModalForm" */ '@stories/Widgets/ModalForm/ModalForm'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'CookieDeclaration',
        import: () => import(/* webpackChunkName: "CookieDeclaration" */ '@stories/Widgets/CookieDeclaration/CookieDeclaration'),
        config: {
            hydrate: 'never',
        },
    },
];
